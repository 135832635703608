import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/product';

const FIREBASE_STORAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/';
const IMAGEKIT_STORAGE_URL = 'https://ik.imagekit.io/d3ddbxom7/';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  @Input() product: Product = {} as Product;
  @Output() click: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public goToProduct(): void {
    this.router.navigate(['/productos/' + this.product._id], {
      relativeTo: this.route,
      skipLocationChange: false,
      replaceUrl: true,
    });
    this.click.emit();
  }

  public hasImage(): boolean {
    return this.product.image_url[0] !== undefined;
  }

  public isSoldOut(): boolean {
    return !this.product.active && this.product.show_in_store;
  }

  public getImageUrl(): string {
    return this.product.image_url[0]
      .replace(FIREBASE_STORAGE_URL, IMAGEKIT_STORAGE_URL)
      .concat('&tr=h-80');
  }
}
