<button (click)="goToProduct()" class="flex w-full gap-5 p-8">
  <div class="h-[80px] w-[30%] flex">
    <ng-container *ngIf="hasImage()">
      <img
        [src]="getImageUrl()"
        [alt]="product.name"
        data-sizes="auto"
        [id]="product._id"
        class="h-[80px] w-[80px] object-contain lazyload"
      />
    </ng-container>
  </div>
  <div class="flex flex-col h-full w-[70%] text-start">
    <span class="product-name">
      {{ product.name }}
    </span>
    <span class="text-primary font-bold text-[14px] w-full">
      {{ product.price | currency }}
    </span>
    <p class="product-description" *ngIf="product.description">
      {{ product.description }}
    </p>
    <span class="sold-out" *ngIf="isSoldOut()">Agotado</span>
  </div>
</button>
